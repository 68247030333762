import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const IndustryRecPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityIndustryRecognitionPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const pageProps = {
    _id: data.sanityIndustryRecognitionPage._id,
    metadata: { ...data.sanityIndustryRecognitionPage._rawRoute, href },
    sections: data.sanityIndustryRecognitionPage._rawSections,
    bubbleCtaSection: data.sanityIndustryRecognitionPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      locale={locale}
      menuColor="white"
      sanityId={pageProps._id}
      {...pageProps.metadata}
    >
      {renderPageSections(pageProps.sections)}
      {pageProps.bubbleCtaSection && (
        <BubbleCtaSection {...pageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

IndustryRecPage.propTypes = {
  data: PropTypes.shape({ sanityIndustryRecognitionPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

IndustryRecPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export default IndustryRecPage;

export const query = graphql`
  query($slug: String) {
    sanityIndustryRecognitionPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
